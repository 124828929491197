$(document).ready(function() {
  var x, i, j, l, ll, selElmnt, a, b, c;
  /*look for any elements with the class "ds-select":*/
  x = document.getElementsByClassName("ds-select");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /*for each element, create a new button that will act as the selected item:*/
    a = document.createElement("button");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);

    /*for each element, create a new ul that will contain the option list:*/
    b = document.createElement("ul");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < ll; j++) {
      /*for each option in the original select element,
    create a new li that will act as an option item:*/
      c = document.createElement("li");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.setAttribute("value", selElmnt.options[j].value);
      c.setAttribute("tabindex", "0");

      function handleEvent(e) {
        /*when an item is clicked, update the original select box,
        and the selected item:*/
        if (e.type == "click" || e.keyCode === 13) {
          var y, i, k, s, h, sl, yl;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("ds-selected");
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "ds-selected");
              break;
            }
          }
          h.click();
        }
      }

      c.addEventListener("click", handleEvent);
      c.addEventListener("keydown", handleEvent);

      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function(e) {
      /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
      e.stopPropagation();
      e.preventDefault();
      closeAllSelect(this);
      if (!this.parentElement.classList.contains("disabled")) {
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("ds-select-active");

        if (
          this.classList.contains("ds-select-active") &&
          !this.parentElement.classList.contains("is-invalid")
        ) {
          // this.parentElement.style.border = "1px solid #3ca078";
          this.parentElement.classList.add("is-active");
        } else if (
          !this.classList.contains("ds-select-active") &&
          !this.parentElement.classList.contains("is-invalid")
        ) {
          // this.parentElement.style.border = "1px solid #f7f7f7";
          this.parentElement.classList.remove("is-active");
        } else if (
          this.classList.contains("ds-select-active") &&
          this.parentElement.classList.contains("is-invalid")
        ) {
          // this.parentElement.style.border = "1px solid #af0818";
          this.parentElement.classList.add("is-invalid-opne");
        }
      }
    });

    //   focus button
    a.addEventListener("focus", function() {
      if (!this.parentElement.classList.contains("disabled")) {
        this.parentElement.classList.add("is-focus");
      }
    });
    a.addEventListener("blur", function() {
      this.parentElement.classList.remove("is-focus");
      this.parentElement.classList.remove("is-active");
      this.parentElement.classList.remove("is-invalid-opne");
    });
  }
  function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
  except the current select box:*/
    var x,
      y,
      i,
      xl,
      yl,
      arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove("ds-select-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }
  /*if the user clicks anywhere outside the select box,
then close all select boxes:*/
  document.addEventListener("click", closeAllSelect);
});
