$( document ).ready(function() {

	var n = 1;

		obj_tabs = $( ".ds-tab-accordion" ).toArray();
		jQuery.each( obj_tabs, function( i, val ) 
		{
			var concat = '';
			
			obj_tab = $(this).find( ".ds-tabs li" ).toArray();
			obj_cont = $(this).find( ".tab-content .tab-pane" ).toArray();
			jQuery.each( obj_tab, function( i,val ) 
			{
				var title = capitalizeFirstLetter(val.innerText);
				concat += '<div id="' + n + '" class="panel panel-default">';
				concat += '<div class="panel-heading accordion-toggle collapsed" role="tab" id="heading' + n + '" data-toggle="collapse" data-parent="#content" data-target="#collapse' + n + '" aria-expanded="false" aria-controls="collapse' + n + '">';
				concat += '<div class="panel-title">';
				concat += '<h3>' +title + '</h3>';
				concat += '<p> See more <i class="ds-chevron-down"></i></p>';
				concat += '</div>';
				concat += '</div>';
				concat += '<div id="collapse' + n + '" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading' + n + '">';
				concat += '<div class="panel-body">' + obj_cont[i].innerHTML + '</div>';
				concat += '</div>';
				concat += '</div>';
				n++;
			});
			$(this).find("#content").html(concat);
			$(this).find("#content").find('.panel-collapse:first').addClass("in");
			$(this).find("#content").find('.panel-title a:first').attr("aria-expanded","true");
			});

			if ($("html").attr("dir") == "ltr") {
  
				$('.accordion-toggle p')
				  .text("See more")
				  .append("<i class='ds-chevron-down'></i>");
			  } else {
				
				$('.accordion-toggle p')
				  .text("عرض المزيد")
				  .append("<i class='ds-chevron-down'></i>");
			  }

});


$(document).on( 'click', '.ds-tab-accordion .accordion-toggle',function(e) {
  e.stopPropagation();
e.stopImmediatePropagation();
  if ($(this).hasClass("collapsed")) {
   if ($("html").attr("dir") == "ltr") {
  
		$(this).find("p")
		  .text("See more")
		  .append("<i class='ds-chevron-down'></i>");
	  } else {
		
		$(this).find("p")
		  .text("عرض المزيد")
		  .append("<i class='ds-chevron-down'></i>");
	  }
  } else {
	  if ($("html").attr("dir") == "ltr") {
    
		$(this).find("p")
		  .text("See less")
		  .append("<i class='ds-chevron-down'></i>");
	  } else {
		
		$(this).find("p")
			.text("إخفاء المزيد")
		  .append("<i class='ds-chevron-down'></i>");
	  }
  }
});


function capitalizeFirstLetter(string) {
	return string.trim().charAt(0).toUpperCase() + string.trim().slice(1).toLowerCase();
  }