$(document).on("click", ".ds-life-experience-card", function() {
  var dataredirecturl = $(this).attr("data-redirecturl");
  if (dataredirecturl != "/") {
    window.location = dataredirecturl;
  }
});

$(document).ready(function() {
  if ($("html").attr("dir") == "ltr") {
    $(".see-more-bnt")
      .text("See more")
      .append("<i class='ds-chevron-down'></i>");
  } else {
    $(".see-more-bnt")
      .text("عرض المزيد")
      .append("<i class='ds-chevron-down'></i>");
  }

  $(".see-more-bnt").click(function(e) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    if ($(this).hasClass("active")) {
      if ($("html").attr("dir") == "ltr") {
        $(this)
          .text("See more")
          .append("<i class='ds-chevron-down'></i>");
      } else {
        $(this)
          .text("عرض المزيد")
          .append("<i class='ds-chevron-down'></i>");
      }

      $(this)
        .parent()
        .parent()
        .next()
        .slideToggle(400);

      $(this).removeClass("active");

      $(this)
        .parent()
        .parent()
        .removeClass("active-header-section");
      $(this)
        .parent()
        .parent()
        .children()
        .first()
        .css("display", "block");
      $(this)
        .parent()
        .parent()
        .children()
        .eq(1)
        .css("display", "none");
    } else {
      if ($("html").attr("dir") == "ltr") {
        $(this)
          .text("See less")
          .append("<i class='ds-chevron-down'></i>");
      } else {
        $(this)
          .text("إخفاء المزيد")
          .append("<i class='ds-chevron-down'></i>");
      }

      $(this)
        .parent()
        .parent()
        .next()
        .slideToggle(400);

      $(this).addClass("active");
      $(this)
        .parent()
        .parent()
        .addClass("active-header-section");
      $(this)
        .parent()
        .parent()
        .children()
        .first()
        .css("display", "none");
      $(this)
        .parent()
        .parent()
        .children()
        .eq(1)
        .css("display", "block");
    }
  });
});
