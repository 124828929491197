$(document).ready(function () {
    if ($(window).width() > 767) {
        $(".ds-sideNavigation-content li a").click(function () {
            $("html, body").animate({scrollTop: $($(this).attr("href")).offset().top}, 10);
            return false;
        });
    }

    // run always
    $(window).bind("scroll", function () {
        // $(".ds-sideNavigation-content li").removeClass("active");
        const currentTop = $(window).scrollTop();
        const elems = $("section");
        elems.each(function (index) {
            // proceed only if has id
            const id = $(this).attr("id");
            if (id) {
                const elemTop = $(this).offset().top;
                let height = Math.round($(this).height());
                if (height === 0) {
                    if (elems[index + 1]) {
                        // get top of next element
                        let nexElemTop = $(elems[index + 1]).offset().top;
                        height = nexElemTop - elemTop;
                    } else {
                        // last element, take height of document
                        let dh = $(document).height();
                        height = dh - elemTop;
                    }
                }
                const elemBottom = elemTop + height;
                if (currentTop >= elemTop && currentTop <= elemBottom) {
                    const navElem = $('a[href="#' + id + '"]');
                    const hasClassActive = navElem.parent().hasClass("active");
                    if (!hasClassActive) {
                        navElem.parent().addClass("active").siblings().removeClass("active");
                        const title = navElem.parent().text()
                        $('#Selected-nav-lable').text(title)
                    }
                }
            }
        });
    });

    $("html").css({scrollBehavior: "smooth"});
});
