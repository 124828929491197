$(".ds-slider .carousel-item").each(function() {
  if ($(window).width() >= 1710) {
    console.log("hi");
    var minPerSlide = 2;
    var next = $(this).next();
  } else {
    var minPerSlide = 4;
    var next = $(this);
  }

  if (!next.length) {
    next = $(this).siblings(":first");
  }
  next
    .children(":first-child")
    .clone()
    .appendTo($(this));

  for (var i = 0; i < minPerSlide; i++) {
    next = next.next();
    if (!next.length) {
      next = $(this).siblings(":first");
    }

    next
      .children(":first-child")
      .clone()
      .appendTo($(this));
  }
});

$(document).ready(function() {
  $("#carouselExampl").on('swiperight',function() {
    $("#carouselExampl").carousel("prev");
  });
  $("#carouselExampl").on('swipeleft',function() {
    $("#carouselExampl").carousel("next");
  });
});
